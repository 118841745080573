// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'
import QRCode from 'qrcode.react'
import firebase from 'firebase/app'
import 'firebase/auth'

// Actions
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'
import {fetchPatientData} from '../actions/fetchPatientData.js'

// Components
import {
  AsideDiv,
  Switch,
  FloatingButton,
  Button,
} from '@anishp16/lazarus-cds'
// import {
//   AsideDiv,
//   PatientHeader,
//   Switch,
//   FloatingButton,
//   Button,
//   Input,
//   SearchBar,
//   OrgMemberThumb,
// } from 'lazarus-cds'
import TestThumbs from './TestThumbs'
import Slider from '@material-ui/core/Slider'
import ValueLabel from './ValueLabel'
import ImageUpload from './ImageUpload'
import ContactPatient from './ContactPatient'
import FullLoadingScreen from './FullLoadingScreen'
import FloatingActionButton from './FloatingActionButton'
import PatientHeaderContainer from './PatientHeaderContainer'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
// import 'lazarus-cds/dist/index.css'
import '../styles/Patient.css'

// Images
import twoDot from '../images/twoDot.svg'
import filter from '../images/filter.svg'
import flag from '../images/flag.svg'
import notes from '../images/notes.svg'
import hourglass from '../images/Hourglass.svg'
import classification from '../images/classification.svg'
import accuracy from '../images/accuracy.svg'
import check from '../images/check.svg'
import manFront from '../images/wirePerson/manFront.jpg'
import manBack from '../images/wirePerson/manBack.jpg'
import womanFront from '../images/wirePerson/womanFront.jpg'
import womanBack from '../images/wirePerson/womanBack.jpg'
import Test from './Test.js'
import upload from '../images/white/upload.svg'

/*
  Renders patient page with test filters, location, and
  a list of test links (local, mproc, and mole tracking)
*/

function Patient(props) {
  const userId = firebase && firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const xAxis = React.createRef()
  const yAxis = React.createRef()

  const conditionDict = { // used for filtering tests by condition
    'Actinic keratoses': true,
    'Basal cell carcinoma': true,
    'Benign keratosis-like lesions ': true,
    // 'Dermatofibroma': true,
    'Melanocytic nevi': true,
    'Melanoma': true,
    // 'Vascular lesions': true,
  }

  const [confidenceRange, setConfidenceRange] = useState([0, 99])
  const [conditions, setConditions] = useState(conditionDict)
  const [areConditionsRendered, setAreConditionsRendered] = useState(false)
  const [isAccuracyFilterRendered, setIsAccuracyFilterRendered] =
    useState(false)
  const [filters, setFilters] = useState({})
  const [qrCode, setQrCode] = useState(false)
  const [id, setId] = useState(null)
  const [patient, setPatient] = useState(null)
  // const [test, setTest] = useState(null)
  // Set gender and back/front for image
  const [imageOrientation, setImageOrientation] = useState(manFront)
  const [locationImage, setLocationImage] = useState(manFront)
  /* Redirect to '/' if no valid patient id */
  const [redirect, setRedirect] = useState(false)
  const [isUnresolvedTests, setIsUnresolvedTests] = useState(false)
  const [patientTests, setPatientTests] = useState(null)
  const [isTestRequest, setIsTestRequest] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [areFloatingButtons, setAreFloatingButtons] = useState(false)
  const [areFloatingButtonsShowing, setAreFloatingButtonsShowing] = useState(false)
  const [isTestUpload, setIsTestUpload] = useState(false)

  const tests = patientTests || []
  // const tests = [demoTest, demoTest, demoMProcTest]

  const renderedTests = tests
  useEffect(() => {
    props.storePayload({
      currentTest: null,
      currentTestSet: null,
      isFabOpen: false,
    })
  }, [])

  /* Fetch tests */
  useEffect(() => {
    const id = props.match.params.patientId
    setId(id)
    const {isPatientDataFetched} = props
    if (isPatientDataFetched) {
      if (id) { // id found in url
        if (isPatientDataFetched === 'yes') { // found patient
          setPatientTests(props.patientsData[id].tests)
          setIsLoading(false)
        } else if (isPatientDataFetched && isPatientDataFetched === 'no') { // no patient with this id
          setRedirect(true)
          setIsLoading(false)
        }
      } else { // no id
        setRedirect(true)
        setIsLoading(false)
      }
    } else if (id) { // patient data not fetched
      // console.log('no patient data, fetching...')
      props.fetchPatientData(props.firestore, [id], props.patientsData)
    } else {
      setRedirect(true)
    }
  }, [props.patientsData[id], props.isPatientDataFetched, props.patientsDataFetchTime])

  /* Set patient data from organization patient doc */
  useEffect(() => {
    if (props.orgPatientsObj && Object.keys(props.orgPatientsObj) && props.orgData && id) {
      const patient = props.orgPatientsObj[id]
      /* Check permission before setting patient */
      const viewAllPatients = (
        props.orgData.Permissions[userId]['View all patient accounts']
      )
      if (patient && (patient.SharedWith[userId] || viewAllPatients)) {
        if (patient && patient.Sex === 'female') {
          setImageOrientation(womanFront)
          setLocationImage(womanFront)
        }
        setPatient(patient)
        props.storePayload({
          currentPatient: patient,
        })
      } else {
        console.log('User does not have permission or patient isnt in orgPatientsObj')
        setRedirect(true)
      }
    }
  }, [props.orgPatientsObj, props.orgData, id])

  /*  To Do: consolidate tests iterations with filterTests to
      avoid iterating over tests multiple times
  */
  let flaggedTestCount = 0
  let notesTestCount = 0
  let pendingFollowUpCount = 0
  /* all unique conditions found in top result of tests */
  let suspectedConditionsDict = {}

  const closeFab = () => {
    props.storePayload({
      isFabOpen: false,
      isFabShowing: false,
      isGiantBlueCircle: false,
    })
  }

  const onClickFABBtn = (cb) => {
    if (props.subData.status === 'active') {
      cb()
    } else {
      props.storePayload({
        userMessage: 'This is a paid feature, please go to settings to set up a subscription plan.',
        notificationType: 3,
        noticationIcon: 'warning',
      })
    }
  }

  tests.forEach((test) => {
    let mProcFlagCount = 0
    let mProcNotesCount = 0
    if (test.Type === 'Local') {
      if (test.Flag) {
        flaggedTestCount++
      }
      if (test.Notes && test.Notes.length > 0) {
        notesTestCount++
      }
      if (!suspectedConditionsDict[test.Results]) {
        suspectedConditionsDict[test.Results] = true
      }
    } else if (test.Type === 'M-Proc') {
      if (!test.IsEmailSent) {
        pendingFollowUpCount++
      }
      for (let i = 0; i < test.TestSets[0].Tests.length; i++) {
        const mProcTest = test.TestSets[0].Tests[i]
        if (mProcTest.data.Flag) {
          mProcFlagCount = 1
        }
        if (mProcTest.data.Notes && mProcTest.data.Notes.length > 0) {
          mProcNotesCount = 1
        }
        if (mProcTest.data.Results && !suspectedConditionsDict[mProcTest.data.Results]) {
          suspectedConditionsDict[mProcTest.data.Results] = true
        }
      }
      flaggedTestCount += mProcFlagCount
      notesTestCount += mProcNotesCount
    } else if (test.Type === 'Mole-Tracking') {
      if (test.Flag) {
        flaggedTestCount++
      }
      if (test.PhysicianNotes && test.PhysicianNotes.length > 0) {
        notesTestCount++
      }
    }
  })
  const filteredTests =
    renderedTests ? renderedTests.filter((test) => {
      const diseaseFilter = (disease) => {
        switch (disease) {
          case 'Actinic keratoses':
          case 'Basal cell carcinoma':
          case 'Benign keratosis-like lesions ': /* There's a space after this in firestore */
          case 'Dermatofibroma':
          case 'Melanocytic nevi':
          case 'Melanoma':
          case 'Vascular lesions':
            if (conditions[disease] && filters.classification) {
              return true
            } else if (filters.classification) {
              return false
            } else {
              return true
            }
          default: return true
        }
      }
      // const date = (dateTaken) =>
      //   filters['date'] ? (
      //     new Date(dateTaken).getTime() >=
      //     new Date(dateMin).getTime()
      //   ) : true
      const flag = (testFlag) => filters['flag'] ? testFlag : true
      const notes = (testNotes) => filters['notes'] ? testNotes : true
      const pendingFollowUp = (test) => filters['pendingFollowUp'] ? (test.Type === 'M-Proc' && !test.IsEmailSent) : true
      const accuracy = (confidence) => filters['accuracy'] ? (
        confidence >= (confidenceRange[0] / 100) &&
        confidence <= (confidenceRange[1] / 100 + 0.01)
        ) : true
      if (test.Type === 'Local') {
        return (
          accuracy(test.Confidence) && flag(test.Flag) && notes(test.Notes) &&
          diseaseFilter(test.Results) && pendingFollowUp(test)
        )
      } else if (test.Type === 'M-Proc') {
        return (
          test.TestSets[0].Tests.some((mProcTest) => {
            return (
              accuracy(mProcTest.data.Confidence) &&
              flag(mProcTest.data.Flag) &&
              notes(mProcTest.data.Notes) &&
              diseaseFilter(mProcTest.data.Results) &&
              pendingFollowUp(test)
            )
          })
        )
      } else if (test.Type === 'Mole-Tracking') {
        return (
          accuracy(test.Confidence) && flag(test.Flag) && notes(test.PhysicianNotes) &&
          diseaseFilter(test.Results) && pendingFollowUp(test)
        )
      }
    }) : []

  const sortedFilteredTests = filteredTests.sort((a, b) => {
    const aDate = new Date(a.DateTaken)
    const bDate = new Date(b.DateTaken)
    // return bDate - aDate
    return ((b.Type !== 'Mole-Tracking') ? b.DateTaken : b.LastUpdated) - ((a.Type !== 'Mole-Tracking') ? a.DateTaken : a.LastUpdated)
  })

  const onChangeSwitch = (filter) => {
    setFilters({
      ...filters,
      [filter]: !filters[filter],
    })
  }

  const resetFilters = () => {
    setFilters({})
    setConditions(conditionDict)
    setConfidenceRange([0, 99])
  }

  const onClickTestSetThumb = (newTest) => {
    /*  onClickTestSetThumb in TestThumbs component will handle
        animations and rendering of testThumbs
        This function will handle zooming into coordinates
    */
    const coordinates = newTest.MoleCoordinates || [0, 0]
    const orientation = setNewOrientation(newTest)
    cropSelector(coordinates, orientation)
  }

  const setNewOrientation = (newTest) => {
    let orientation
    if (newTest && patient) {
      if (!newTest.Front) {
        if (patient.Sex === 'female') {
          orientation = womanBack
          setImageOrientation(womanBack)
          setLocationImage(womanBack)
        } else {
          orientation = manBack
          setImageOrientation(manBack)
          setLocationImage(manBack)
        }
      } else {
        if (patient.Sex === 'female') {
          orientation = womanFront
          setImageOrientation(womanFront)
          setLocationImage(womanFront)
        } else {
          orientation = manFront
          setImageOrientation(manFront)
          setLocationImage(manFront)
        }
      }
    } else {
      orientation = manFront
      setLocationImage(manFront)
    }
    return orientation
  }

  function setLocationCrosshairs(coordinates = [0, 0]) {
    const yA = document.getElementById('y-axis')
    const xA = document.getElementById('x-axis')
    if (xA && yA) {
      yA.style.width = `${coordinates[0]}%`
      xA.style.height = `${coordinates[1]}%`
    } else {
    }
  }

  const onHoverTestSetThumb = (coordinates = [0, 0], newTest) => {
    // Reset to uncropped image
    // const locationImageToCrop = returnLocationImageToCrop()
    // setTest(newTest)
    setNewOrientation(newTest)
    // setLocationImage(imageOrientation)
    setLocationCrosshairs(coordinates)
  }

  const onMouseLeaveTestSetThumb = () => {
    // setLocationImage(imageOrientation)
    setLocationCrosshairs([0, 0])
  }

  const returnLocationImageToCrop = (orientation) => {
    if (orientation === manFront) {
      return document.getElementById('invisible-manFront')
    } else if (orientation === manBack) {
      return document.getElementById('invisible-manBack')
    } else if (orientation === womanFront) {
      return document.getElementById('invisible-womanFront')
    } else if (orientation === womanBack) {
      return document.getElementById('invisible-womanBack')
    }
  }

  const cropSelector = async (coordinates, orientation = imageOrientation) => {
    // const coordinates = [50, 50]
    const shrinkRatio = 0.5
    const canvas = returnLocationImageToCrop(orientation) // hidden img element from App.js
    const x = (coordinates[0] / 100 * canvas.width) - (canvas.width / 2 * shrinkRatio)
    const y = (coordinates[1] / 100 * canvas.height) - (canvas.height / 2 * shrinkRatio)
    const crop = {
      unit: 'px',
      height: canvas.height * shrinkRatio,
      width: canvas.width * shrinkRatio,
      x: x,
      y: y,
    }

    const croppedImg =
      await Helpers.getCroppedImg(canvas, crop, 'croppedLocation')
    setLocationImage(croppedImg[0])
    /* Important! crop moves location to center */
    setLocationCrosshairs([50, 50])
  }

  const toggleIsTestUpload = () => {
    setIsTestUpload(!isTestUpload)
    props.storePayload({isGiantBlueCircle: false})
  }

  const onClickSendTestRequest = (message) => {
    if (!props.userMessage) { // Prevent button spamming
      setIsLoading(true)
      const time = Date.now()
      const url = `https://testing.d1dy0a21ndt7ut.amplifyapp.com/?state=${userId}&patient=${props.currentPatient.ID}&orgId=${props.userData.Membership.Organization}`
      const dataToSend = {
        patient_email: props.currentPatient.Email,
        physician_id: userId,
        patient_id: props.currentPatient.ID,
        notes: message,
        test_time: time,
        test_id: props.currentPatient.ID,
        url: url,
        org_id: props.userData.Membership.Organization,
      }
      const jsonData = JSON.stringify(dataToSend)
      fetch(process.env.REACT_APP_URL + 'sendTestRequest', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      })
          .then((response) => response.json())
          .then((data) => {
            setIsLoading(false)
            props.storePayload({
              userMessage: `Success! An email has been sent to this patient.
              You will receive an email notification once the patient completes the test.
              
              Once the test is completed by the patient, you can filter for this test through the "Awaiting Follow-up" filter on the Test Thumbnail Page`,
              notificationType: 2,
              notificationIcon: 'check',
              testRequestMessage: null,
            })
            setIsTestRequest(false)
          })
          .catch((error) => {
            console.error('Error:', error)
            props.storePayload({
              userMessage: 'There was an error sending an email to this patient. Please try again and contact Lazarus for support if this problem persists.',
              notificationType: 3,
              notificationIcon: 'warning',
            })
            setIsLoading(false)
            setIsTestRequest(false)
          })
    }
  }

  return (
    <Fragment>
      {!userId || props.isLoading || isLoading ? <FullLoadingScreen /> : (
      <Fragment>
        {redirect ? <Redirect to='/' /> : (
        <div className='patient-page'>
          {!isTestUpload && !isTestRequest &&
          <FloatingActionButton
            onClick={() => {
              setIsTestUpload(false)
              props.storePayload({
                isFabOpen: true,
                isFabShowing: !props.isFabShowing,
                isGiantBlueCircle: !props.isGiantBlueCircle,
              })
            }}
            isX={props.isFabShowing}
          />
          }
          <Fragment>
            {!isTestRequest &&
              <Fragment>
                {patient &&
                <div className='patient-header-container'>
                  <PatientHeaderContainer patient={patient} />
                </div>
                }
                <main className='patient-page-main'>
                  <aside>
                    <AsideDiv
                      type={1}
                      text={'All Tests'}
                      number={tests.length}
                      // switchText='By Date'
                      // image={twoDot}
                      // onClick={() => setIsUnresolvedTests(!isUnresolvedTests)}
                      // style={{cursor: 'pointer'}}
                    />
                    <div className='filter-div'>
                      <div className='img-circle'>
                        <img src={filter} alt='filter' />
                      </div>
                      <p>SORT, FILTER</p>
                      <div
                        className='small-btn'
                        onClick={resetFilters}
                      >
                        <p>Reset</p>
                      </div>
                    </div>
                    <div className='filter-div'>
                      <div className='title'>
                        <img src={flag} alt='flag' />
                        <p>Flagged Tests</p>
                      </div>
                      <div className='flex'>
                        <p className='font-small'>
                          {flaggedTestCount}
                        </p>
                        <Switch
                          checked={filters.flag}
                          onChange={() => onChangeSwitch('flag')} />
                      </div>
                    </div>
                    <div className='filter-div'>
                      <div className='title'>
                        <img src={notes} alt='notes' />
                        <p>With Notes</p>
                      </div>
                      <div className='flex'>
                        <p className='font-small'>
                          {notesTestCount}
                        </p>
                        <Switch
                          checked={filters.notes}
                          onChange={() => onChangeSwitch('notes')}
                        />
                      </div>
                    </div>
                    <div className='filter-div'>
                      <div className='title'>
                        <img src={hourglass} alt='hourglass' />
                        <p>Pending Follow-up</p>
                      </div>
                      <div className='flex'>
                        <p className='font-small'>
                          {pendingFollowUpCount}
                        </p>
                        <Switch
                          checked={filters.pendingFollowUp}
                          onChange={() => onChangeSwitch('pendingFollowUp')}
                        />
                      </div>
                    </div>
                    {props.isAIActive &&
                      <Fragment>
                        <div
                          className={
                            filters['classification'] ?
                            'filter-div tab selected-tab' :
                            'filter-div tab'}
                          onClick={() => {
                            onChangeSwitch('classification')
                            setAreConditionsRendered(true)
                          }}
                        >
                          <div className='title'>
                            <img src={classification} alt='classification' />
                            <p>Classification</p>
                          </div>
                          <div className='flex'>
                            <p className='font-small'>
                              {Object.keys(suspectedConditionsDict).length !== 1 ?
                                `${Object.keys(suspectedConditionsDict).length} classes` :
                                `${Object.keys(suspectedConditionsDict).length} class`
                              }
                            </p>
                          </div>
                        </div>
                        {areConditionsRendered &&
                          <div className={
                            filters['classification'] ?
                            'expand-down classification-list' :
                            'collapse-up classification-list'
                          }>
                            {Object.keys(conditionDict).map((condition, i) => {
                              return (
                                <div
                                  className='condition'
                                  key={i}
                                  onClick={() => setConditions({
                                    ...conditions,
                                    [condition]: !conditions[condition],
                                  })}
                                >
                                  <div>
                                    {conditions[condition] && <img src={check} alt='check' />}
                                  </div>
                                  <p>{condition}</p>
                                </div>
                              )
                            })}
                          </div>
                        }
                        <div
                          className={
                            filters['accuracy'] ?
                            'filter-div tab selected-tab' :
                            'filter-div tab'}
                          onClick={() => {
                            onChangeSwitch('accuracy')
                            setIsAccuracyFilterRendered(true)
                          }}
                        >
                          <div className='title'>
                            <img src={accuracy} alt='accuracy' />
                            <p>Accuracy</p>
                          </div>
                          <div className='flex'>
                            <p className='font-small'>
                              {'0-99%'}
                            </p>
                          </div>
                        </div>
                        {isAccuracyFilterRendered &&
                          <div className={
                            filters['accuracy'] ?
                            'expand-down accuracy-div' :
                            'collapse-up accuracy-div'
                          }>
                            <span className="margin-r-5">0%</span>
                            <Slider
                              onChange={(e, val) => {
                                setConfidenceRange(val)
                              }}
                              ValueLabelComponent={ValueLabel}
                              defaultValue={0}
                              value={confidenceRange}
                              max={99}
                              style={{width: '60%', display: 'inline-block'}}
                            />
                            <span className="margin-l-5">99%</span>
                          </div>
                        }
                      </Fragment>
                    }
                  </aside>
                  <div className='wire-person-div'>
                    <div className='wire-person-container'>
                      <img src={locationImage} alt='location' />
                      <div className='axis-container'>
                        <div id='x-axis' ref={xAxis}></div>
                        <div id='y-axis' ref={yAxis}></div>
                      </div>
                    </div>
                  </div>
                  <div className='test-thumbs-div'>
                    <TestThumbs
                      onClickTestSetThumb={onClickTestSetThumb}
                      onHoverTestSetThumb={onHoverTestSetThumb}
                      onMouseLeaveTestSetThumb={onMouseLeaveTestSetThumb}
                      tests={sortedFilteredTests}
                      fullTestList={patientTests} // used to check if all tests are loaded
                      {...props}
                    />
                    {/* <p>December</p> */}
                  </div>
                  {qrCode &&
                    <Fragment>
                      <div className='gray-screen' onClick={() => setQrCode(!qrCode)} />
                      <div className='qr-code-modal'>
                        <QRCode className="qr" size={256} value={Helpers.qrLink(id, props.currentPatient.ID, props.userData.Membership.Organization)} />
                        <Button
                          text='Close'
                          type={2}
                          onClick={() => setQrCode(!qrCode)}
                        />
                      </div>
                    </Fragment>
                  }
                </main>
                {props.isFabOpen &&
                <div
                  className={
                    isTestUpload ? 'test-upload-container' :
                      props.isFabShowing ? 'menu slideInLeft' : 'menu slideOutRight'
                  }
                >
                  <FloatingButton
                    text='P&#10552;'
                    isP={true}
                    onClick={
                      () => {
                        /* Check permission */
                        if (props.orgData &&
                        props.orgData.Permissions[userId]['Send test requests']) {
                          onClickFABBtn(
                              () => {
                                closeFab()
                                setIsTestRequest(!isTestRequest)
                              })
                        } else { /* No permission */
                          props.storePayload({
                            userMessage: 'You do not have permission to send test requests',
                            notificationType: 3,
                            notificationIcon: 'warning',
                          })
                        }
                      }
                    }
                    label={'Request image from patient'}
                  />
                  {props.orgData &&
                  props.orgData.Permissions[userId]['Create tests'] &&
                  props.orgPatientsObj[props.currentPatient.ID].SharedWith[userId] && (
                    <Fragment>
                      <FloatingButton
                        text='QR'
                        onClick={
                          () => onClickFABBtn(
                              () => {
                                closeFab()
                                setQrCode(!qrCode)
                              })
                        }
                        label={'Generate QR code'}
                      />
                      {props.subData.status === 'active' ?
                        <ImageUpload
                          isTest={true}
                          onClick={
                            () => onClickFABBtn(toggleIsTestUpload)
                          }
                          closeFab={closeFab}
                        /> :
                        <FloatingButton
                          image={upload}
                          text='Upload'
                          label='Upload image from device'
                          onClick={
                            () => onClickFABBtn(
                                () => null,
                            )
                          }
                        />
                      }
                    </Fragment>
                  )}
                </div>
                }
              </Fragment>
            }
            {isTestRequest && patient &&
              <ContactPatient
                patient={patient}
                isTestRequest={true}
                cancel={() => setIsTestRequest(false)}
                onClickSendTestRequest={onClickSendTestRequest}
                // setContactPatient={setContactPatient}
              />
            }
          </Fragment>
        </div>
        )}
      </Fragment>
      )}
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  path: state.userReducer.path,
  patientsObj: state.userReducer.patientsObj,
  userData: state.firebaseReducer.userData,
  orgData: state.userReducer.orgData,
  orgPatientsObj: state.userReducer.orgPatientsObj,
  testsObj: state.userReducer.testsObj,
  currentPatient: state.userReducer.currentPatient,
  patientsData: state.userReducer.patientsData,
  isLoading: state.userReducer.isLoading,
  userMessage: state.userReducer.userMessage,
  notificationType: state.userReducer.notificationType,
  isFabOpen: state.userReducer.isFabOpen,
  isFabShowing: state.userReducer.isFabShowing,
  isGiantBlueCircle: state.userReducer.isGiantBlueCircle,
  isAIActive: state.userReducer.isAIActive,
  subData: state.userReducer.subscriptionData,
  isPatientDataFetched: state.userReducer.isPatientDataFetched,
  orgPhysiciansData: state.userReducer.orgPhysiciansData,
  patientsDataFetchTime: state.userReducer.patientsDataFetchTime,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload, fetchPatientData},
)(Patient)

