import firebase from 'firebase/app'
import types from '../types.js'

export const fetchOrgPatients = (firestore, orgId) => (dispatch) => {
  /*
    '.onSnapshot' will resolve before fetching all of the
    documents in the collection. However '.get' will get the full
    collection when it resolves. The colleciton size is pulled with
    '.get' and 'attachListener' will only dispatch once it has all
    documents in the collection
  */
  firestore.collection('LCDS') // Fetch userData
      .doc('Something')
      .collection('Organizations')
      .doc(orgId)
      .collection('Patients')
      .get()
      .then((res) => {
        const collectionSize = res.size
        attachListener(collectionSize)
      })

  const attachListener = (collectionSize) => {
    firestore.collection('LCDS') // Fetch userData
        .doc('Something')
        .collection('Organizations')
        .doc(orgId)
        .collection('Patients')
        .onSnapshot((res) => {
          if (res) {
            // res.docChanges().forEach((change) => {
            //   console.log(change)
            // })
            const orgPatientsDocsArr = res.docs
            const orgPatientsObj = {}
            let orgPatientList = orgPatientsDocsArr.map((patientDoc) => {
              const patient = patientDoc.data()
              orgPatientsObj[patient.ID] = patient
              return patient
            })
            orgPatientList = orgPatientList.sort((a, b) => {
              return b.LastUpdate - a.LastUpdate
            })
            if (orgPatientList.length === collectionSize) {
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  orgPatientList,
                  orgPatientsObj,
                },
              })
            }
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                orgPatientList: null,
                orgPatientsObj: null,
              },
            })
          }
        }, (error) => console.log(error))
  }
}
