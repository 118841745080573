import types from '../types.js'

/*  Takes an array of patient Id's from 'Something/Organization/Patients' and
    fetches the patient data (tests) from 'Something/Patients
    Stores {patientId: {...res}} as patientsData in userReducer
*/
export const fetchPatientData =
(firestore, patientIds, patientsData) => (dispatch) => {
  patientIds.forEach(async (patientId) => {
    const patientDoc = await
    firestore.collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(patientId)
        .get()
        .catch(() => {
          console.log('Error finding patient')
          return 'error'
        })
    if (!patientDoc) {
      console.log('no patient doc')
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          userMessage: 'There was an error finding patient with Id: ' + patientId,
          notificationType: 2,
          notificationIcon: 'warning',
          isPatientDataFetched: 'no',
        },
      })
    }
    firestore.collection('LCDS')
        .doc('Something')
        .collection('Patients')
        .doc(patientId)
        .collection('DermatologyTests')
        .onSnapshot((res) => {
          // console.log('res', res)
          if (res.docs) {
            const patientTestDocs = res.docs
            const patientTests = patientTestDocs.map((doc) => {
              return (
                {...doc.data(), Id: doc.id}
              )
            })
            const data = patientDoc.data()
            patientsData[patientId] = {tests: patientTests, data}
            // const patientsDataKeys = Object.keys(patientsData)
            /* After last test (check because async) */
            // if (patientIds.length === patientsDataKeys.length) {
            // console.log('patient data being stored', patientsData)
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                patientsData,
                isPatientDataFetched: 'yes',
                patientsDataFetchTime: Date.now(),
              },
            })
          } else {
            console.log('error finding tests')
          }
        })
  })
}

