// Dependencies
// Dependencies
import React, {useState, useEffect, Fragment} from 'react'
import firebase from 'firebase/app'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import Helpers from '../Helpers.js'

// Actions
import {storeValue} from '../actions/storeValue'
import {storePayload} from '../actions/storePayload'
import {createPatient} from '../actions/createPatient'

// Components
import {Header, Button} from '@anishp16/lazarus-cds'
import NewPatientForm from './NewPatientForm'
import FullLoadingScreen from './FullLoadingScreen'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
import '../styles/AddPatient.css'

// Images
import User from '../images/white/User.svg'
import add from '../images/white/Add.svg'
import notesImg from '../images/notes.svg'

function TestRequest(props) {
  const textAreaRef = React.createRef()
  const [patientImage, setPatientImage] = useState(null)
  const [patient, setPatient] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSummary, setIsSummary] = useState(false)
  const [id, setId] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const id = urlParams.get('id')
    setId(id)
    setPatient(props.patientsObj[id])
  }, [props.patientsObj])

  useEffect(() => {
    if (patient) {
      const defaultTestRequestMessage = `Hi ${patient.FirstName || '[Patient Name]'} ${patient.LastName || ''},\r\n\r\nPlease complete your scheduled skin cancer self-assessment for ${props.userData.Info.Name}. To begin, open the link below, preferably on a mobile device.\r\n\r\nFor a skin cancer screening, please collect three images of your pigmented skin lesion and upload them through our portal. ${props.userData.Info.Name} will review them promptly and reach out to explain what you will need to do next.\r\n\r\nSincerely, ${props.userData.Info.Name}`
      if (textAreaRef.current) {
        textAreaRef.current.value = defaultTestRequestMessage
        props.storeValue('testRequestMessage', defaultTestRequestMessage)
      }
    }
  }, [patient])

  const onClickSendTestRequest = (message) => {
    if (!props.userMessage) { // Prevent button spamming
      setIsLoading(true)
      // props.storePayload({isLoading: true})
      const userId = firebase.auth().currentUser.uid
      const url = `https://testing.d1dy0a21ndt7ut.amplifyapp.com/?state=${userId}&patient=${patient.ID}`
      const dataToSend = {
        patient_email: patient.Email,
        physician_id: userId,
        patient_id: patient.ID,
        notes: props.testRequestMessage,
        url: url,
        org_id: props.userData.Membership.Organization,
      }
      const jsonData = JSON.stringify(dataToSend)
      fetch(process.env.REACT_APP_URL + 'sendTestRequest', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      })
          .then((response) => response.json())
          .then((data) => {
            setTimeout(() => {
              setIsLoading(false)
              // alert('Email sent')
              props.storePayload({
                userMessage: `Success! An email has been sent to this patient.
                You will receive an email notification once the patient completes the test.
                
                Once the test is completed by the patient, you can filter for this test through the "Awaiting Follow-up" filter on the Test Thumbnail Page`,
                notificationType: 2,
                notificationIcon: 'check',
                testRequestMessage: null,
                requestSent: true,
              })
            }, 1000)
          })
          .catch((error) => {
            console.error('Error:', error)
            props.storePayload({
              userMessage: 'There was an error sending an email to this patient. Please try again and contact Lazarus for support if this problem persists.',
              notificationType: 3,
              notificationIcon: 'warning',
            })
            setIsLoading(false)
          })
    }
  }

  return (
    <div className='add-patient'>
      {props.requestSent ?
      <Redirect to='/mproc' /> : (
      <Fragment>
        {(isLoading || props.isLoading) && <FullLoadingScreen />}
        {patient &&
          <Fragment>
            <Header
              image={props.userData.Info.ImageRef || User}
              name={props.userData.Info.Name}
              practice={props.userData.Info.Practice}
            />
            <main>
              <div className='add-image-div'>
                <div className='test-request-img-wrapper'>
                  {/* {patientImage ?
                    <img src={patientImage} alt='' /> :
                    <img src={add} alt='add image' />
                  } */}
                  <img
                    className='test-request-img'
                    src={patient.ImageRef || User} alt=''
                  />
                </div>
              </div>
              <div className='form-div'>
                <h1>
                  Test Request Review
                </h1>
                <NewPatientForm
                  isTestRequest={true}
                  firstName={patient.FirstName ? patient.FirstName : patient.Name.split(' ')[0]}
                  lastName={patient.LastName ? patient.LastName : patient.Name.split(' ')[1]}
                  birthDate={patient.Birthday}
                  sex={patient.Sex}
                  phone={patient.Phone}
                  email={patient.Email}
                  gender={patient.PreferredGender || null}
                />
              </div>
              <div className='rightCol'>
                <div className='rightCol-top' />
                <div>
                  <Fragment>
                    <div className='message-div flex justify-space-between'>
                      <p>Message To Patient</p>
                      <img src={notesImg} alt='' />
                    </div>
                    <div className='text-area-container'>
                      <textarea
                        ref={textAreaRef}
                        onChange={(event) => {
                          props.storeValue(
                              'testRequestMessage',
                              event.target.value,
                          )
                        }}
                      />
                      {isSummary &&
                      <div className='text-area-overlay'>
                        <Button
                          text='Edit'
                          type={2}
                          onClick={() => setIsSummary(false)}
                          width={'calc(100% - 2em)'}
                          style={{
                            position: 'absolute',
                            bottom: '1em',
                            width: 'calc(100% - 2em)',
                            left: '1em',
                            zIndex: 3,
                          }}
                        />
                      </div>
                      }
                    </div>
                  </Fragment>
                </div>
                <Button
                  text={isSummary ? 'Send Test Request' : 'Send'}
                  type={1}
                  onClick={isSummary ? onClickSendTestRequest : () => setIsSummary(true)}
                />
              </div>
            </main>
          </Fragment>
        }
      </Fragment>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  newPatient: state.userReducer.newPatient,
  isMprocRequest: state.userReducer.isMprocRequest,
  userData: state.firebaseReducer.userData,
  requestSent: state.userReducer.requestSent,
  personImageUrl: state.userReducer.personImageUrl,
  patientsObj: state.userReducer.patientsObj,
  testRequestMessage: state.userReducer.testRequestMessage,
  currentPatient: state.userReducer.currentPatient,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload, createPatient},
)(TestRequest)
