// Dependencies
import React, {useEffect, useState, Fragment} from 'react'
import firebase from 'firebase/app'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import Helpers from '../Helpers.js'
import QRCode from 'qrcode.react'
import {PDFDownloadLink} from '@react-pdf/renderer'

// Actions
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'
import {fetchPatientData} from '../actions/fetchPatientData.js'

// Components
import {
  AsideDiv,
  Switch,
  Input,
  FloatingButton,
  ConfidenceBar,
  Loader,
  Button,
  Modal,
} from '@anishp16/lazarus-cds'
import TestPdf from './TestPdf'
import LocalTestPdf from './LocalTestPdf'
import ImageUpload from './ImageUpload'
import ContactPatient from './ContactPatient'
import FullLoadingScreen from './FullLoadingScreen'
import FloatingActionButton from './FloatingActionButton'
import PatientHeaderContainer from './PatientHeaderContainer'

// Styles
import '../styles/Test.css'

// Images
import accuracy from '../images/accuracy.svg'
import warning from '../images/warning.svg'
import blockGreen from '../images/blockGreen.svg'
import flagImg from '../images/flag.svg'
import notesImg from '../images/notes.svg'
import manFront from '../images/wirePerson/manFront.jpg'
import manBack from '../images/wirePerson/manBack.jpg'
import womanFront from '../images/wirePerson/womanFront.jpg'
import womanBack from '../images/wirePerson/womanBack.jpg'

function Test(props) {
  const userId = firebase && firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const pdfUrl = process.env.REACT_APP_PDF_URL
  const [flag, setFlag] = useState(false)
  const [heatMap, setHeatMap] = useState(false)
  const [notes, setNotes] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [timeOfType, setTimeOfType] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [patient, setPatient] = useState(null)
  const [patientId, setPatientId] = useState(null)
  const [testId, setTestId] = useState(null)
  const [mProcId, setMProcId] = useState(null)
  const [testSetIndex, setTestSetIndex] = useState(null)
  const [patientTests, setPatientTests] = useState(null)
  const [qrCode, setQrCode] = useState(false)
  const [test, setTest] = useState(null)
  const [testData, setTestData] = useState(null)
  const [contactPatient, setContactPatient] = useState(false)
  const [isPdfReady, setIsPdfReady] = useState(false)
  const [isPatientClicked, setIsPatientClicked] = useState(false)
  const [isTestRequest, setIsTestRequest] = useState(false)
  const [isTestUpload, setIsTestUpload] = useState()
  const [areFloatingButtons, setAreFloatingButtons] = useState(false)
  const [areFloatingButtonsShowing, setAreFloatingButtonsShowing] = useState(false)
  const [areNotesUpdated, setAreNotesUpdated] = useState(false)
  const notesRef = React.createRef()
  const asideDivStyle = {backgroundColor: 'white', cursor: 'pointer'}

  useEffect(() => {
    props.storePayload({isFabOpen: false})
  }, [])

  /* If accessed through patient page */
  useEffect(() => {
    if (props.currentTest) {
      const currentTest = props.currentTest
      if (currentTest.data) { // m-proc
        setFlag(currentTest.data.Flag)
        if (notesRef.current && !areNotesUpdated) {
          notesRef.current.value = test.data.Notes || ''
        }
      } else {
        setFlag(currentTest.Flag)
        if (notesRef.current && !areNotesUpdated) {
          notesRef.current.value = test.Notes || ''
        }
      }
      setTest(currentTest)
      setIsLoading(false)
    }
  }, [props.currentTest])

  /* If url accessed directly */
  useEffect(() => {
    if (props.orgPatientsObj && (!props.currentPatient || !props.currentTest)) {
      const id = props.match.params.patientId
      if (props.orgPatientsObj[id]) {
        const patient = props.orgPatientsObj[id]
        patient['Patient_ID'] = id
        /* Check permission before setting patient */
        const viewAllPatients = (
          props.orgData.Permissions[userId]['View all patient accounts']
        )
        if (patient && (patient.SharedWith[userId] || viewAllPatients)) {
          props.fetchPatientData(props.firestore, [id], {})
          setPatient(patient)
          props.storeValue('currentPatient', patient)
          props.storeValue('path', 'patient')
        } else {
          setRedirect(true)
        }
        /* If there are patients but not one with this id */
      } else if (Object.keys(props.patientsObj).length) {
        setRedirect(true)
      }
    }
  }, [props.orgPatientsObj])

  /* Set notes once text area is rendered */
  useEffect(() => {
    if (!areNotesUpdated && notesRef.current) {
      if (test.data) { // m-proc format
        notesRef.current.value = test.data.Notes || ''
        setAreNotesUpdated(true)
      } else {
        notesRef.current.value = test.Notes || ''
        setAreNotesUpdated(true)
      }
    }

    /* accommodate tablet sizing */
    const mains = document.getElementsByTagName('MAIN')
    if (mains.length) {
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const main = mains[0]
      if (main) {
        const em = parseFloat(getComputedStyle(main).fontSize)
        // main.style.height = `${windowHeight}px`
        main.style.width = `${windowWidth - (12.5 * em)}px`
        main.style.height = `${windowHeight - (9 * em)}px`
      }
    }
  }, [notesRef])

  /*  To enable direct access to link,
      store patient and test ids (and optional mprocId) from url params
      If no patient matches patientId, redirect
      If no patientsData, fetch patient's data (tests)
  */
  useEffect(() => {
    const patientId = props.match.params.patientId
    setPatientId(patientId)
    const testId = props.match.params.testId
    setTestId(testId)
    const mProcId = props.match.params.mProcId
    if (mProcId) {
      console.log('mProc id', mProcId)
      const testSetIndex = mProcId[mProcId.length - 1]
      console.log('test set index', testSetIndex)
      setTestSetIndex(testSetIndex)
      setMProcId(mProcId)
    }
    let patient

    if (props.currentPatient) {
      patient = props.currentPatient
    } else if (props.orgPatientsObj && props.orgPatientsObj[patientId]) {
        patient = props.orgPatientsObj[patientId]
    } else if (props.orgPatientsObj && Object.keys(props.orgPatientsObj).length) {
      setRedirect(true)
    }
    setPatient(patient)
    if (Object.keys(props.patientsData).length) {
      /* ? */
    } else if (props.firestore) {
      props.fetchPatientData(props.firestore, [patientId], {})
    }
  }, [props.orgPatientsObj])

  /*  (If url directly accessed) Once tests are fetched (in fetchPatientData)
      store relevant data in component state using url params
  */
  useEffect(() => {
    if (!props.currentTest && patientId && testId && Object.keys(props.patientsData)) {
      const patientTests = props.patientsData[patientId].tests
      setPatientTests(patientTests)
      /* test is either the local test or the m-proc group of tests */
      const test = patientTests.filter((patientTest) => patientTest.Id === testId)[0]
      if (test) {
        /* For M-Proc tests */
        if (test.Type === 'M-Proc' && mProcId) {
          const mProcTestIndex = mProcId[mProcId.length - 1]
          const mProcTest = test.TestSets[0].Tests[mProcTestIndex]
          if (mProcTest) { // mProc test exists
            // setTestId(mProcTest)
            setFlag(mProcTest.Flag)
            setNotes(mProcTest.Notes)
            setTest(mProcTest)
            props.storeValue('currentTestSet', test)
            props.storeValue('currentTest', mProcTest)
            setIsLoading(false)
          } else { // mProc test with url params doesn't exist
            setIsLoading(false)
            setRedirect(true)
          }
        } else { // No mProc id in url
          // setTestId(test)
          setFlag(test.Flag)
          setNotes(test.Notes)
          setTest(test)
          props.storeValue('currentTest', test)
          setIsLoading(false)
        }
      } else { // Local test with id from url params doens't exist
        setIsLoading(true)
        setRedirect(true)
      }
    }
  }, [props.patientsData])

  // useEffect(() => {
  //   if (isPdfReady) {
  //     setInterval(async () => {
  //       // button was being triggered several times
  //       if (document.getElementById('pdf-ready') && isPdfReady) {
  //         await document.getElementById('pdf-ready').click()
  //         await setIsPdfReady(false)
  //       }
  //     }, 4000)
  //   }
  // }, [isPdfReady])

  // useEffect(() => {
  //   if (lesionDivRef.current) {
  //     const width = lesionDivRef.current.clientWidth
  //     console.log('width', width)
  //     lesionDivRef.current.style.MaxHeight = width
  //   }
  // }, [lesionDivRef])

  const formatFileNameDate = (str) => {
    if (str) {
      const fileNameDate = str.slice(0, 4) + '_' + str.slice(5, str.length)
      return fileNameDate.replace('-', '_')
    } else {
      return ''
    }
  }

  const toggleIsTestUpload = () => {
    setIsTestUpload(!isTestUpload)
    props.storePayload({isGiantBlueCircle: false})
  }

  const closeFab = () => {
    props.storePayload({
      isFabOpen: false,
      isFabShowing: false,
      isGiantBlueCircle: false,
    })
  }

  /* Update Flag/Notes values and update props.patientsData */
  const updateTest = (key, value) => {
    if (test.Type === 'Local') {
      props.firestore.
          collection('LCDS')
          .doc('Something')
          .collection('Patients')
          .doc(patientId)
          .collection('DermatologyTests')
          .doc(testId)
          .update({
            [key]: value,
          })
      /* Update test in front end */
      const newPatientsData = props.patientsData
      let testIndex
      /* Find index of test (indexOf(test) didn't work) */
      newPatientsData[patientId].tests.forEach((patientTest, i) => {
        if (patientTest.Id === test.Id) {
          testIndex = i
        }
      })
      newPatientsData[patientId].tests[testIndex][key] = value
      props.storeValue('patientsData', newPatientsData)
    } else { // m-proc test
      const newTests = props.currentTestSet.TestSets[0].Tests
      newTests[testSetIndex] = {
        ...newTests[testSetIndex],
        data: {
          ...newTests[testSetIndex].data,
          [key]: value,
        },
      }
      props.firestore.
          collection('LCDS')
          .doc('Something')
          .collection('Patients')
          .doc(patientId)
          .collection('DermatologyTests')
          .doc(props.currentTestSet.Id)
          .update({
            ...props.currentTestSet,
            Updated: true,
            TestSets: [ // Always array of 1 for now
              {
                ...props.currentTestSet.TestSets[0],
                Tests: [
                  ...newTests,
                ],
              },
            ],
          })
          // .then(() => console.log('successfully updated test'))
          .catch(() => console.log('failed to update test'))
      /* Update test in front end */
      const newPatientsData = props.patientsData
      let testIndex
      /* Find index of test (indexOf(test) didn't work) */
      newPatientsData[patientId].tests.forEach((patientTest, i) => {
        if (patientTest.Id === props.currentTestSet.Id) {
          testIndex = i
        }
      })
      // const testIndex = newPatientsData[patientId].tests.indexOf(test)
      newPatientsData[patientId].tests[testIndex].TestSets[0].Tests[testSetIndex][key] = value
      props.storeValue('patientsData', newPatientsData)
    }
  }

  /*
    Submit notes after user stops typing for set amount of time
  */
  useEffect((pauseLength = 2000) => {
    const timer = setTimeout(() => {
      const now = Date.now()
      if (isTyping && (now - timeOfType) >= pauseLength) {
        setIsTyping(false)
        updateTest('Notes', notes)
      }
    }, pauseLength)
    return () => clearTimeout(timer)
  }, [timeOfType])

  const onChangeNotes = async (event) => {
    await setNotes(event.target.value)
    setIsTyping(true)
    setTimeOfType(Date.now())
  }

  const testToRender = test
  // const testData = test && (
  //   test.Type === 'Local' ? test : test.data
  // )

  useEffect(() => {
    const testData = test && (
      test.Type === 'Local' ? test : test.data
    )
    setTestData(testData)
  }, [test])

  /* Sort hight to low */
  const sortedConditions = (reasoning) => {
    return (
      reasoning.sort((a, b) => b.Confidence - a.Confidence)
    )
  }

  /* Conditions to be rendered under first result */
  const lesserConditions =
    testToRender ? (
      testToRender.Type === 'Local' ? (
        sortedConditions(testToRender.Reasoning)
            .slice(1, sortedConditions(testToRender.Reasoning).length)
      ) : ( // 'M-Proc'
        sortedConditions(testToRender.data.Reasoning)
            .slice(1, sortedConditions(testToRender.data.Reasoning).length)
      )
    ) : {}

  const handleWirePerson = () => {
    if (props.currentPatient && props.currentPatient.Sex === 'Female') {
      if (test && test.Front) {
        return womanFront
      } else {
        return womanBack
      }
    } else {
      if (test && test.Front) {
        return manFront
      } else {
        return manBack
      }
    }
  }

  const onClickGeneratePDF = () => {
    // setAreNotesUpdated(false)
    props.storePayload({
      isLoading: true,
    })
    const testIdToSend = props.currentTestSet ?
    props.currentTestSet.Id :
    props.currentTest.Id
    const data = {
      orgId: props.userData.Membership.Organization,
      // orgId: "28995e34-c6fa-4188-992d-66d52e53012d",
      physicianId: userId,
      // physicianId: "ZgV8UIyF6qaB2bgheCJR4Ooe0MO2",
      patient: props.currentPatient.ID,
      // patient: "ziFYrbH2cyYyGDzQ3dsV",
      test: testIdToSend,
      // test: "0c984689-105a-4865-823a-898a150c1109",
    }
    const dataToSend = JSON.stringify(data)
    fetch(pdfUrl, {
      method: 'POST',
      body: dataToSend,
    })
        .then(async (res) => {
          if (res.status === 200) {
            console.log('status', res.status)
            const content = await res.blob()
            const newBlob = new Blob([content], {type: 'image/pdf'})
            const a = document.createElement('a')
            a.style = 'display: none'
            document.body.appendChild(a)
            // Create a DOMString representing the blob and point the link element towards it
            const url = window.URL.createObjectURL(newBlob)
            a.href = url
            a.download = `${testIdToSend}.pdf`
            // programatically click the link to trigger the download
            a.click()
            // release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url)
            props.storePayload({
              isLoading: false,
            })
          } else {
            console.log('error:', res, res.status)
            props.storePayload({
              isLoading: false,
              userMessage: 'There was an error generating a PDF for this patient',
              notificationType: 3,
              notificationIcon: 'warning',
            })
          }
        })
        .catch((res) => {
          console.log('error:', res)
          props.storePayload({
            isLoading: false,
            userMessage: 'There was an error generating a PDF for this patient',
            notificationType: 3,
            notificationIcon: 'warning',
          })
        })
  }

  const onClickSendFollowUp = (message, isHighConcern) => {
    setIsLoading(true)
    const time = Date.now()
    const concern = isHighConcern ? 'malignant' : 'benign'
    const dataToSend = {
      case: concern,
      patient_email: props.currentPatient.Email,
      physician_id: userId,
      patient_id: props.currentPatient.ID,
      notes: message,
      test_time: time,
      org_id: props.userData.Membership.Organization,
      test_id: props.currentTestSet ? props.currentTestSet.Id : props.currentTest.Id,
    }
    const jsonData = JSON.stringify(dataToSend)
    fetch(process.env.REACT_APP_URL + 'cds-patient-contact', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonData,
    })
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false)
          setContactPatient(false)
          props.storePayload({
            userMessage: `Success! An email has been sent to this patient.`,
            notificationType: 2,
            notificationIcon: 'check',
          })
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('Error:', error)
          setContactPatient(false)
          props.storePayload({
            userMessage: 'There was an error sending an email to this patient. Please try again and contact Lazarus for support if this problem persists.',
            notificationType: 3,
            notificationIcon: 'warning',
          })
        })
  }

  const changeTest = (isForward) => {
    let mProcTestIndex = testSetIndex
    if (isForward) {
      mProcTestIndex++
    } else {
      mProcTestIndex--
    }
    console.log('mProcTestIndex', mProcTestIndex)
    const mProcTest = props.currentTestSet.TestSets[0].Tests[mProcTestIndex]
    setTest(mProcTest)
    console.log('mProcTestIndex', mProcTestIndex)
    setTestSetIndex(mProcTestIndex)
    props.storeValue('currentTest', mProcTest)
  }

  const arePatientNotes = props.currentTestSet && props.currentTestSet.TestSets[0].Notes && props.currentTestSet.TestSets[0].Notes.length

  return (
    <div className='test-page'>
      {(isLoading || props.isLoading) && <FullLoadingScreen />}
        <Fragment>
          {redirect ? <Redirect to={'/'} /> : (
          <Fragment>
            {!contactPatient && test && testData &&
            <Fragment>
              {!isTestUpload &&
                <FloatingActionButton
                  onClick={() => {
                    setIsTestUpload(false)
                    props.storePayload({
                      isFabOpen: true,
                      isFabShowing: !props.isFabShowing,
                      isGiantBlueCircle: !props.isGiantBlueCircle,
                    })
                  }}
                  isX={props.isFabShowing}
                />
              }
              {/* <Link to={`/patient/${patientId}`}> */}
              {patient &&
                <PatientHeaderContainer patient={patient} />
              }
              {/* </Link> */}
              <main id='first-screen'>
                <aside>
                  <AsideDiv
                    text='Test Date'
                    number={Helpers.timeStampToDate(testData.DateTaken)}
                    type={1}
                    switchText={Helpers.timeStampToTime(testData.DateTaken)}
                  />
                  <AsideDiv
                    text='Location'
                    number={test.Type === 'Local' ? (
                      (test.MoleLocation && test.MoleLocation.charAt(0).toUpperCase() + test.MoleLocation.slice(1)) || 'N/A'
                    ) : ( // M-Proc
                     (props.currentTestSet.TestSets[0].MoleLocation && props.currentTestSet.TestSets[0].MoleLocation.charAt(0).toUpperCase() + props.currentTestSet.TestSets[0].MoleLocation.slice(1)) || 'N/A'
                    )
                    }
                    type={2}
                    image={accuracy}
                    style={{backgroundColor: 'white'}}
                  />
                  <AsideDiv
                    text='AI'
                    number='Heat Map'
                    type={2}
                    image={blockGreen}
                    style={asideDivStyle}
                    switch={true}
                    onClick={() => setHeatMap(!heatMap)}
                    /*
                      Hack to allow funcitonality from clicking
                      both switch and AsideDiv
                      onClick reverses switch, onChange keeps it the same
                    */
                    onChange={() => setHeatMap(heatMap)}
                    isSelected={heatMap}
                  />
                  <AsideDiv
                    text='Test'
                    number='Flag Test'
                    type={2}
                    image={flagImg}
                    style={asideDivStyle}
                    switch={true}
                    onClick={() => {
                      setFlag(!flag)
                      updateTest('Flag', !flag)
                    }}
                    /*
                      Hack to allow funcitonality from clicking
                      both switch and AsideDiv
                      onClick reverses switch, onChange "keeps it the same"
                    */
                    onChange={() => {
                      setFlag(flag)
                      updateTest('Flag', flag)
                    }}
                    isSelected={flag}
                  />
                </aside>
                <div className='lesion-div'>
                  <img
                    src={heatMap ? testData.HeatMapRef : testData.ImageRef}
                    alt='lesion'
                  />
                  {/* <div /> */}
                  {props.currentTest.data &&
                    <div className='carousel-nav'>
                      <div>
                        {props.currentTest.data && testSetIndex > 0 &&
                        /*  Link (for unknown reason) updates url but doesn't rerender page. Clicking the link triggers changeTest()
                            and updates test (state) from the current test set
                        */
                        <Link to={`/patient/${props.currentPatient.ID}/test/${props.currentTestSet.Id}/${test.id.slice(0, test.id.length - 2)}-${parseInt(testSetIndex) - 1}`}>
                          <p onClick={() => changeTest(false)}>&#10229;</p>
                        </Link>
                        }
                      </div>
                      <p>{`${(parseInt(testSetIndex) + 1).toString()}/3`}</p>
                      <div>
                        {props.currentTest.data && testSetIndex < 2 &&
                        <Link to={`/patient/${props.currentPatient.ID}/test/${props.currentTestSet.Id}/${test.id.slice(0, test.id.length - 2)}-${parseInt(testSetIndex) + 1}`}>
                          <p onClick={() => changeTest(true)}>&#10230;</p>
                        </Link>
                        }
                      </div>
                    </div>
                  }
                </div>
                <div className='results-notes-div'>
                  <div className='results-div'>
                    <div className='flex justify-space-between'>
                      <p className='gray'>Results</p>
                      <p className='gray'>Confidence</p>
                    </div>
                    <div className='conditions-div'>
                      <div className='margin-bottom-05em condition-div'>
                        <div className='flex justify-space-between'>
                          <p className='bold upperCase'>
                            {testData.Results}
                          </p>
                          <p className='bold'>
                            {testToRender.Type === 'Local' ?
                            (testToRender.Confidence * 100).toString().slice(0, 5) + '%' :
                            (testToRender.data.Confidence * 100).toString().slice(0, 5) + '%'
                            }
                          </p>
                        </div>
                        <ConfidenceBar
                          singleBar={{
                            concern: Helpers.returnConditionRiskLevel(testData.Results),
                            confidence: testData.Confidence * 100,
                          }}
                        />
                      </div>
                      {lesserConditions.map((condition, i) => {
                        return (
                          <div className='margin-bottom-05em condition-div' key={i}>
                            <div className='flex justify-space-between'>
                              <p className='bold upperCase'>{condition.Name}</p>
                              <p className='bold'>{condition.Confidence.toString().slice(0, 5) + '%'}</p>
                            </div>
                            <ConfidenceBar
                              singleBar={{
                                concern: Helpers.returnConditionRiskLevel(condition.Name),
                                confidence: condition.Confidence,
                              }}
                            />
                          </div>
                        )
                      })
                      }
                    </div>
                  </div>
                  <div className={arePatientNotes ? 'notes-div patient-notes-div relative' : 'notes-div relative'}>
                    <div className='flex justify-space-between'>
                      <p>Notes</p>
                      <img src={notesImg} alt='' />
                    </div>
                    {arePatientNotes &&
                      <div className='patient-notes'>
                        <p>{`Patient: ${props.currentTestSet.TestSets[0].Notes}`}</p>
                      </div>
                    }
                    <textarea
                      maxLength='500'
                      onChange={onChangeNotes}
                      className={isTyping || (!notes || !notes.length) ? 'isTyping' : 'isNotTyping'}
                      ref={notesRef}
                    />
                    {isTyping && (
                      <Loader style={{height: '2em', bottom: '0.75em', left: 0}}/>
                    )}
                  </div>
                </div>
                <div className='contact-button-div'>
                  <Button
                    text='Contact Patient'
                    type={1}
                    onClick={() => setContactPatient(true)}
                  />
                </div>
              </main>
              {props.isFabOpen &&
              <div
                className={
                  isTestUpload ? 'test-upload-container' :
                    props.isFabShowing ? 'menu slideInLeft' : 'menu slideOutRight'
                }
              >
                {/* {test && test.Type === 'M-Proc' &&
                  <FloatingButton
                    text='&#128424;'
                    style={{marginBottom: '0.5em'}}
                  >
                    {isPdfReady &&
                      <PDFDownloadLink
                        document={
                          <TestPdf
                            physicianInfo={props.userData.Info}
                            physicianMessage={test.PhysicianMessage || null}
                            patient={props.currentPatient}
                            wirePerson={handleWirePerson()}
                            patientBirthday={Helpers.formatDate(props.currentPatient.Birthday)}
                            patientNotes={test && test.Type === 'M-Proc' ? test.TestSets[0].Notes : null}
                            testDate={Helpers.formatDate(test && test.DateTaken.slice(0, 10))}
                            testSet={test.testSets[0]}
                            test1Image={test.testSets[0].Tests[0].data.ImageRef}
                            isEmailSent={test ? test.IsEmailSent : null}
                            decision={'N/A'}
                            testSetId = {test.testSets[0].Id && test.testSets[0].Id.slice(0, test.testSets[0].Id.indexOf('-'))}
                          />
                        }
                        fileName={`Dm_${this.formatFileNameDate(test.testSets[0].Tests[0].data.DateTaken.slice(0, 10))}_${props.currentPatient.Name}_${props.userData.Info.Name}.pdf`}
                        className="link"
                      >
                        {({blob, url, loading, error}) => {
                          return (!loading && blob && url &&
                          <div id="pdf-ready"className="btn-div2 pdf-btn-hidden">
                          </div>
                          )
                        }}
                      </PDFDownloadLink>
                    }
                  </FloatingButton>
                } */}
                {test &&
                  <FloatingButton
                    text='&#128424;'
                    textStyle={{color: 'black'}}
                    style={{marginBottom: '0.5em', backgroundColor: 'white', border: '1px solid black'}}
                    label={'Generate PDF of this test'}
                    onClick={() => {
                      onClickGeneratePDF()
                    }}
                  >
                  </FloatingButton>
                }
                {/* {isPdfReady &&
                  <PDFDownloadLink
                    document={
                      <LocalTestPdf
                        physicianInfo={props.userData.Info}
                        physicianMessage={test.PhysicianMessage || null}
                        patient={props.currentPatient}
                        wirePerson={handleWirePerson()}
                        patientBirthday={Helpers.formatDate(props.currentPatient.Birthday)}
                        testDate={Helpers.timeStampToDate(test.DateTaken)}
                        test1Image={test.ImageRef}
                        isEmailSent={test ? test.IsEmailSent : null}
                        decision={'N/A'}
                        testId={test.id}
                        test={test}
                        imageRef={test.ImageRef}
                      />
                    }
                    fileName={`Dm_${formatFileNameDate(test.DateTaken.toString().slice(0, 10))}_${props.currentPatient.Name}_${props.userData.Info.Name}.pdf`}
                    className="link"
                  >
                    {({blob, url, loading, error}) => {
                      return (!loading && blob && url &&
                      <div id="pdf-ready" className="btn-div2 pdf-btn-hidden">
                      </div>
                      )
                    }}
                  </PDFDownloadLink>
                } */}
                {/* <FloatingButton
                  text='P&#10552;'
                  isP={true}
                  style={{marginBottom: '0.5em'}}
                  onClick={() => setIsTestRequest(!isTestRequest)}
                /> */}
                {props.orgData && props.orgData.Permissions &&
                  props.orgData.Permissions[userId]['Create tests'] &&
                  props.orgPatientsObj[props.currentPatient.ID].SharedWith[userId] &&
                  <Fragment>
                    <FloatingButton
                      text='QR'
                      style={{marginBottom: '0.5em'}}
                      onClick={() => {
                        setQrCode(!qrCode)
                        closeFab()
                      }}
                      label='Generate QR code'
                    />
                    <ImageUpload
                      isTest={true}
                      onClick={toggleIsTestUpload}
                      closeFab={closeFab}
                    />
                  </Fragment>
                }
              </div>
              }
            </Fragment>
            }
          </Fragment>
        )}
        </Fragment>
      }
      {qrCode &&
        <Fragment>
          <div className='gray-screen' onClick={() => setQrCode(!qrCode)} />
          <div className='qr-code-modal'>
            <QRCode className="qr" size={256} value={Helpers.qrLink(patientId, props.currentPatient.ID, props.userData.Membership.Organization)} />
            <Button
              text='Close'
              type={2}
              onClick={() => setQrCode(!qrCode)}
            />
          </div>
        </Fragment>
      }
      {isPatientClicked &&
        <div
          className='patient-info-container'
          onClick={() => setIsPatientClicked(false)}
        >
          <div className='patient-info'>
            <Input
              disabled={true}
              label='Phone'
              value={patient.Phone}
              style={{width: '50%'}}
            />
            <Input
              disabled={true}
              label='Email'
              value={patient.Email}
              style={{width: '50%'}}
            />
          </div>
          {/* <div className='btn-container'>
            <Button
              type={2}
              text='Cancel'
            />
            <Button
              type={1}
              text='Merge To Selected'
            />
          </div> */}
        </div>
      }
      {contactPatient && patient && testId &&
        <ContactPatient
          patient={patient}
          testId={testId}
          isTestRequest={false}
          test={props.currentTestSet || props.currentTest}
          cancel={() => setContactPatient(false)}
          onClickSendFollowUp={onClickSendFollowUp}
          testSetIndex={testSetIndex}
        />
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  orgData: state.userReducer.orgData,
  orgPatientsObj: state.userReducer.orgPatientsObj,
  userData: state.firebaseReducer.userData,
  path: state.userReducer.path,
  patientsObj: state.userReducer.patientsObj,
  patientsData: state.userReducer.patientsData,
  currentPatient: state.userReducer.currentPatient,
  currentTest: state.userReducer.currentTest,
  currentTestSet: state.userReducer.currentTestSet,
  qrLink: state.userReducer.qrLink,
  isLoading: state.userReducer.isLoading,
  isFabShowing: state.userReducer.isFabShowing,
  isFabOpen: state.userReducer.isFabOpen,
  isGiantBlueCircle: state.userReducer.isGiantBlueCircle,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload, fetchPatientData},
)(Test)


