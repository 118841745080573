import types from '../types'
import firebase from 'firebase/app'
import 'firebase/auth'
const uuidv4 = require('uuid/v4');

export const signUp = (
    firestore,
    userData,
    orgId, // For Invites (optional)
) => (dispatch) => {
  const newId = uuidv4()
  const formattedUserData = {
    Info: {
      FirstName: userData.firstName,
      LastName: userData.lastName,
      Name: `${userData.firstName} ${userData.lastName}`,
      Email: userData.email,
      Practice: userData.practiceName,
      Address: userData.practiceAddress,
      ImageRef: userData.image ? userData.image : null,
      ImageName: userData.imageName ? userData.imageName : '',
    },
    Settings: {
      IsAIHidden: false,
    },
    Membership: {
      Active: 'created',
      Auth: 'Super Admin',
      Organization: newId,
    },
  }
  const isFormValid = (
    userData.firstName && userData.lastName &&
    formattedUserData.Info.Email &&
    formattedUserData.Info.Practice &&
    formattedUserData.Info.Address
  )

  const doPasswordsMatch = (
    userData.password === userData.confirmPassword
  )
  if (doPasswordsMatch) {
    if (isFormValid) {
      if (!orgId) {
        firebase.auth().createUserWithEmailAndPassword(
            userData.email,
            userData.password,
        )
            .then(signUpSuccessCB)
            .catch(signUpFailureCB)
        function signUpSuccessCB(response) {
          firestore.collection('LCDS') // Add physician info
              .doc('Something')
              .collection('Physicians')
              .doc(firebase.auth().currentUser.uid)
              .set(formattedUserData)
              .then((res) => { // Add permissions
                const orgId = formattedUserData.Membership.Organization
                const formattedOrgData = {
                  Info: {
                    FirstName: userData.firstName,
                    LastName: userData.lastName,
                    Name: `${userData.firstName} ${userData.lastName}`,
                    Email: userData.email,
                    Practice: userData.practiceName,
                    Address: userData.practiceAddress,
                    ImageRef: userData.image ? userData.image : null,
                    ImageName: userData.imageName ? userData.imageName : '',
                  },
                  Owner: firebase.auth().currentUser.uid,
                  Status: 'created',
                }
                firestore.collection('LCDS') // Add physician info
                    .doc('Something')
                    .collection('Organizations')
                    .doc(orgId)
                    .set(formattedOrgData)
                    .then((res) => {
                      fetchOrgDataThenSetPermissions(orgId)
                    })
                    .then(() => {
                      dispatch({
                        type: types.STORE_PAYLOAD,
                        payload: {
                          userMessage: 'Success! Please wait while we verify your credentials',
                          notificationType: 3,
                          notificationIcon: 'check',
                        },
                      })
                      dispatch({
                        type: types.SIGN_UP,
                        payload: {
                          signedIn: false,
                          signedUp: true,
                          // userMessage: 'Success! Please wait while we review your credentials',
                          authStatus: 'success',
                        },
                      })
                    })
              })
              .catch((err) => console.log('error adding physician', err))
        }
      } else { // OrgId exists (This is an invite)
        firestore.collection('LCDS') // Add physician info
            .doc('Something')
            .collection('Organizations') //this won't work atm as this doc now requires you to be logged in so we need to turn the check into an api 
            .doc(orgId)
            .get()
            .then((snapDoc1) => {
              if (snapDoc1.exists() && snapDoc1.data()['Invites'] && snapDoc1.data()['Invites'][userData.email]) {
                const formattedUserData = {
                  Info: {
                    Name: `${userData.firstName} ${userData.lastName}`,
                    Email: userData.email,
                    Practice: userData.practiceName,
                    Address: userData.practiceAddress,
                    ImageRef: userData.image ? userData.image : null,
                    ImageName: userData.imageName ? userData.imageName : '',
                  },
                  Settings: {
                    IsAIHidden: false,
                  },
                  Membership: {
                    Organization: orgId,
                    Active: 'created',
                  },
                  Sub: {
                    isActive: false,
                  },
                }
                firebase.auth().createUserWithEmailAndPassword(
                    userData.email,
                    userData.password,
                )
                    .then(signUpSuccessCB)
                    .catch(signUpFailureCB)
                function signUpSuccessCB(response) {
                  firestore.collection('LCDS') // Add physician info
                      .doc('Something')
                      .collection('Physicians')
                      .doc(firebase.auth().currentUser.uid)
                      .set(formattedUserData)
                      .then((res) => { // Add permissions
                        const orgId = formattedUserData.Membership.Organization
                        fetchOrgDataThenSetPermissions(orgId)
                      })
                      .catch((err) => {
                        dispatch({
                          type: types.SIGN_UP,
                          payload: {
                            signedIn: false,
                            signedUp: false,
                            userMessage: 'There was an error signing up. Please try again later.',
                            authStatus: 'failure',
                          },
                        })
                      })
                }
              }
            })
      }
      function signUpFailureCB(response) {
        dispatch({
          type: types.SIGN_UP,
          payload: {
            signedIn: false,
            signedUp: false,
            userMessage: 'There was an error signing up. Please try again later.',
            authStatus: 'failure',
          },
        })
        dispatch({ // Clear notification
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'There was an error signing up. Please try again later.',
            notificationType: 3,
            notificationIcon: 'warning',
          },
        })
      }
      function fetchOrgDataThenSetPermissions(orgId) {
        /*
          Fetch org data to get existing permissions to prevent overwrite
        */
        firestore.collection('LCDS') // Fetch userData
            .doc('Something')
            .collection('Organizations')
            .doc(orgId)
            .onSnapshot((res) => {
              const orgData = res.data()
              setPermissions(orgData)
            })
      }
      /* Add to existing permissions */
      function setPermissions(orgData) {
        firestore.collection('LCDS')
            .doc('Something')
            .collection('Organizations')
            .doc(formattedUserData.Membership.Organization)
            .update({
              Permissions: {
                ...orgData.Permissions, // existing permissions
                [firebase.auth().currentUser.uid]: {
                  'Send patient accounts': true,
                  'Create patient accounts': true,
                  'Receive patient accounts': true,
                  'View all patient accounts': true,
                  'Create tests': true,
                  'Receive tests': true,
                  'Send test requests': true,
                },
              },
            })
            .then((res) => {
              dispatch({ // store response and signUp success
                type: types.SIGN_UP,
                payload: {
                  signedUp: true,
                },
              })
            })
            .catch((err) => {
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  userMessage: 'There was an error updating permissions for your organization. Please contact Lazarus for assistance.',
                  notificationType: 3,
                  notificationIcon: 'warning',
                },
              })
              // console.log('error updating org permissions', err)
            })
      }
    } else { // form not valid
      dispatch({
        type: types.STORE_PAYLOAD,
        payload: {
          userMessage: 'Please fill out all fields',
          notificationType: 2,
          notificationIcon: 'warning',
        },
      })
    }
  } else { // passwords don't match
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        userMessage: 'Passwords do not match',
        notificationType: 2,
        notificationIcon: 'warning',
      },
    })
  }
}
