// Dependencies
import React, {Fragment, useState, useEffect} from 'react'
import firebase from 'firebase/app'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import Helpers from '../Helpers.js'

// Actions
import {storeValue} from '../actions/storeValue.js'
import {storePayload} from '../actions/storePayload.js'
// import {fetchOrgPatients} from '../actions/fetchOrgPatients.js'
import {fetchPatientData} from '../actions/fetchPatientData.js'

// Components
import {
  Header, PatientThumb, AsideDiv, FloatingButton, SearchBar,
} from '@anishp16/lazarus-cds'
// import {
//   Header,
//   PatientThumb,
//   AsideDiv,
//   FloatingButton,
//   SearchBar,
//   PopUpTab,
//   Notification,
// } from 'lazarus-cds'
import TestLink from './TestLink'
import FullLoadingScreen from './FullLoadingScreen'

// Styles
import '@anishp16/lazarus-cds/dist/index.css'
// import 'lazarus-cds/dist/index.css'
import '../styles/Dashboard.css'

// Images
import User from '../images/white/User.svg'
import Add from '../images/white/Add.svg'
import Search from '../images/white/Search.svg'
import XCircle from '../images/white/XCircle.svg'
import TwoDot from '../images/twoDot.svg'

// Renders Patient list and Aside
function Dashboard(props) {
  const tabDict = { // Initial tabs state
    myPatients: false,
    allPatients: false,
    mproc: false,
    awaiting: false,
    documentation: false,
    settings: false,
    remoteTestLink: false,
  }

  const userId = firebase && firebase.auth().currentUser ? firebase.auth().currentUser.uid : null
  const [tabs, setTabs] = useState({tabDict})
  const [searchValue, setSearchValue] = useState(null)
  const [isSearchBarShowing, setIsSearchBarShowing] = useState(false)
  const [isSearchBarRendered, setIsSearchBarRendered] = useState(false)
  const [patients, setPatients] = useState([])
  /* If isOrgPatients, all organization's patients are rendered */
  const [isOrgPatients, setIsOrgPatients] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [awaitingFollowUpPatients, setAwaitingFollowUpPatients] = useState([])
  const [mProcPendingPatients, setMprocPendingPatients] = useState([])
  const [orgAwaitingFollowUpPatients, setOrgAwaitingFollowUpPatients] = useState([])
  const [orgMprocPendingPatients, setOrgMprocPendingPatients] = useState([])
  const [isAwaitingFollowUpSelected, setIsAwaitingFollowUpSelected] = useState(false)
  const [isNotificationVisible, setIsNotificationVisible] = useState(true)

  /* Set default tab to myPatients, select dashboard tab */
  useEffect(() => {
    onClick('myPatients')
    props.storePayload({
      pages: {
        ...props.pagesDict,
        'dashboard': true,
      },
      isPatientDataFetched: false,
      currentPatient: null,
      currentTest: null,
      currentTestSet: null, // this is used for both mproc and moletracking tests
      // userMessage: null,
      // notificationType: null,
      // notificationIcon: null,
    })
  }, [])

  /* Resize main for screen flip */
  // useEffect(() => {
  //   // resize main to fit window, create firebase
  //   const resize = () => {
  //     const windowHeight = window.innerHeight
  //     const windowWidth = window.innerWidth
  //     const mains = document.getElementsByTagName('MAIN')
  //     const main = mains[0]
  //     const em = parseFloat(getComputedStyle(main).fontSize)
  //     // main.style.height = `${windowHeight}px`
  //     main.style.width = `${windowWidth - (12.5 * em)}px`
  //     main.style.height = `${windowHeight - (9 * em)}px`
  //     // width: calc(100% - 12.5em);
  //     // height: calc(100% - 3em);
  //   }
  //   window.addEventListener('resize', resize)
  //   window.addEventListener('orientationchange', resize)
  //   resize()
  // }, [])

  // useEffect(() => {
  //   if (!props.userMessage) {
  //     onClickNotification()
  //   }
  // }, props.userMessage)

  /* Populate patients with patientList */
  useEffect(() => {
    if (props.patientList.length) {
      const patientIdList = []
      const newMprocPendingPatients = []
      const newAwaitingFollowUpPatients = []

      /*  Sort patients for 'Awaiting follow-up/m-proc pending
          (my patients-not all)
      */
      props.patientList.forEach((patient) => {
        patientIdList.push(patient.ID)
        if (patient.PendingCompletion && patient.PendingCompletion.includes(userId)) {
          newMprocPendingPatients.push(patient)
        }
        if (patient.PendingReview && patient.PendingReview.length) {
          newAwaitingFollowUpPatients.push(patient)
        }
      })

      setMprocPendingPatients(newMprocPendingPatients)
      setAwaitingFollowUpPatients(newAwaitingFollowUpPatients)
    }
    setPatients(props.patientList)
  }, [props.patientList])

  /* Clear search and stop filtering */
  useEffect(() => {
    setSearchValue(null)
  }, [isSearchBarShowing])

  /* Wait for org data */
  useEffect(() => {
    if (props.orgData && props.orgData.Permissions) {
    }
  }, [props.orgData])

  /* Set [patients] according to isOrgPatients and search value */
  useEffect(() => {
    /* All patients is selected */
    if (isOrgPatients && props.orgPatientList) {
      if (searchValue) { // Filter if search value remains
        onChangeSearchBar(props.orgPatientList, searchValue)
      } else {
        setPatients(props.orgPatientList)
      }
    /* My patients is selected */
    } else if (!isOrgPatients && props.patientList) {
      if (searchValue) { // Filter if search value remains
        onChangeSearchBar(props.patientList, searchValue)
      } else {
        setPatients(props.patientList)
      }
    }
  }, [isOrgPatients, props.orgPatientList])

  useEffect(() => {
    const newOrgMprocPendingPatients = []
    const newOrgAwaitingFollowUpPatients = []
    if (props.orgPatientList && props.orgPatientList.length) {
      props.orgPatientList.forEach((patient) => {
        if (patient.PendingCompletion && patient.PendingCompletion.includes(userId)) {
          newOrgMprocPendingPatients.push(patient)
        }
        if (patient.PendingReview && patient.PendingReview.length) {
          newOrgAwaitingFollowUpPatients.push(patient)
        }
      })
    }
    setOrgMprocPendingPatients(newOrgMprocPendingPatients)
    setOrgAwaitingFollowUpPatients(newOrgAwaitingFollowUpPatients)
  }, [props.orgPatientList])

  /*  When patients are updated (all-org/my),
      sort mProcPending/Awaiting Follow-up from that list
      This could be optimized by storing both lists in
      a useEffect listener to props.patientList for 'my patients'
      and aseEffect listener to props.orgPatientList for 'all patients'
  */

  // useEffect(() => {
  //   const newMProcPendingPatients = []
  //   const newAwaitingFollowUpPatients = []
  //   props.patientList.forEach((patient) => {
  //     if (patient.PendingCompletion) {
  //       newMProcPendingPatients.push(patient)
  //     }
  //     if (patient.PendingReview) {
  //       newAwaitingFollowUpPatients.push(patient)
  //     }
  //   })
  //   setMProcPendingPatients(newMProcPendingPatients)
  //   setAwaitingFollowUpPatients(newAwaitingFollowUpPatients)
  // }, patients)

  function onClick(tab) { // Toggle tab
    if (props.subData.status === 'active' || tab === 'myPatients') {
      setTabs({
        ...tabDict,
        [tab]: true,
      })
    } else {
      props.storePayload({
        userMessage: 'This is a paid feature, please go to settings to set up a subscription plan.',
        notificationType: 3,
        noticationIcon: 'warning',
      })
    }
  }

  /*
    When user hides (or shows) search bar, delete previous search
    value, and restore full patient list
  */

  const updateSearchBar = () => {
    if (isOrgPatients) {
      onChangeSearchBar(props.orgPatientList, null)
    } else {
      onChangeSearchBar(props.patientList, null)
    }
  }
  useEffect(updateSearchBar, [isSearchBarShowing])

  const onChangeSearchBar = (patients, value) => {
    setSearchValue(value)
    const searchedPatients = isOrgPatients ? Helpers.filterByName(props.orgPatientList, value) : Helpers.filterByName(props.patientList, value)
    setPatients(searchedPatients)
  }

  const viewAllPatients = (
    props.orgData &&
    firebase.auth().currentUser &&
    props.orgData.Permissions[userId]['View all patient accounts']
  )

  const createPatients = (
    props.orgData &&
    firebase.auth().currentUser &&
    props.orgData.Permissions[userId]['Create patient accounts']
  )

  // const onClickNotification = () => {
  //   setIsNotificationVisible(false)
  //   setTimeout(() => {
  //     props.storeValue('userMessage', null)
  //   }, 300)
  // }

  const renderedPatients =
    isOrgPatients ? (
      isAwaitingFollowUpSelected ? orgAwaitingFollowUpPatients :
      tabs['mproc'] ?
        orgMprocPendingPatients :
        patients
    ) : (
      isAwaitingFollowUpSelected ? awaitingFollowUpPatients :
      tabs['mproc'] ?
        mProcPendingPatients :
        patients
    )
  return (
    <div className='dashboard'>
      {props.isLoading || !firebase.auth().currentUser ? <FullLoadingScreen /> : (
      <Fragment>
        <Header
          image={props.userData.Info.ImageRef || User}
          name={props.userData.Info.Name}
          practice={props.userData.Info.Practice}
        />
        <main>
          <aside>
            <AsideDiv
              text={'My Patients'}
              number={
                props.patientList.length
              }
              isSelected={tabs['myPatients']}
              type={1} // For large number
              onClick={() => {
                onClick('myPatients')
                setIsOrgPatients(false)
                /* View all patient accounts permmission */
              }}
              style={
                viewAllPatients ?
                {cursor: 'pointer'} :
                {}
              }
            />
            <AsideDiv
              text={'All Patients'}
              number={
                props.orgPatientList && props.orgPatientList.length
              }
              isSelected={tabs['allPatients']}
              type={1} // For large number
              onClick={() => {
                if (props.subData.status === 'active') {
                  /* View all patient accounts permmission */
                  if (viewAllPatients) {
                    onClick('allPatients')
                    setIsOrgPatients(true)
                  } else {
                    props.storePayload({
                      userMessage: 'You do not have permission to view all patients',
                      notificationType: 3,
                      notificationIcon: 'warning',
                    })
                  }
                }
              }}
              style={
                viewAllPatients ?
                {cursor: 'pointer'} :
                {}
              }
            />
            {/* <AsideDiv
              text='M-Proc Pending'
              number={
                isOrgPatients ?
                  orgMprocPendingPatients ? orgMprocPendingPatients.length : '' :
                  mProcPendingPatients ? mProcPendingPatients.length : ''
              }
              isSelected={tabs['mproc']}
              type={1}
              onClick={() => {
                onClick('mproc')
              }}
              style={{cursor: 'pointer'}}
            /> */}
            <AsideDiv
              text='Awaiting Follow-up'
              number={
                isOrgPatients ?
                  orgAwaitingFollowUpPatients && orgAwaitingFollowUpPatients.length :
                  awaitingFollowUpPatients && awaitingFollowUpPatients.length
              }
              isSelected={isAwaitingFollowUpSelected}
              type={1}
              onClick={() => {
                setIsAwaitingFollowUpSelected(!isAwaitingFollowUpSelected)
              }}
              style={{cursor: 'pointer'}}
            />
            {props.orgData.Sonnet && props.orgData.Sonnet.Show && <AsideDiv
              text='Patient Portal Link'
              number={''}
              isSelected={tabs['remoteTestLink']}
              onClick={() => onClick('remoteTestLink')}
              type={1}
              style={{cursor: 'pointer'}}
            />}
            <AsideDiv
              text='Total Patients'
              number={props.orgPatientList ? props.orgPatientList.length : 0}
              isSelected={false}
              type={2} // For small number
            />
            {/* <AsideDiv
              text='Tests Completed'
              number={1500}
              isSelected={false}
              type={2}
            /> */}
            {/* <AsideDiv
              text='Lazarus Index'
              number={854}
              isSelected={false}
              type={2}
            /> */}
          </aside>
          {!tabs['remoteTestLink'] &&
          <div className='patient-list'>
            <div className='patients'>
              <div style={{width: 'calc(100% - 1em'}}>
                {isSearchBarRendered &&
                  <SearchBar
                    onChange={(event) => {
                      event.preventDefault()
                      onChangeSearchBar(patients, event.target.value)
                    }}
                    isShowing={isSearchBarShowing}
                    value={searchValue}
                  />
                }
              </div>
              {renderedPatients && renderedPatients.map((patient, i) => {
                const hasPermission =
                  props.orgData.Permissions[userId]['View all patient accounts'] ||
                  !patient.SharedWith ||
                  patient.SharedWith[userId]
                return (
                  <Link
                    onClick={
                      () => {
                        if (!hasPermission) {
                          props.storePayload({
                            userMessage: 'You do not currently have permssion to view this patient.',
                            notificationType: 3,
                            notificationIcon: 'warning',
                          })
                        }
                      }
                    }
                    to={hasPermission ? `/patient/${patient.ID}` : '/'}
                    key={i}
                  >
                    <PatientThumb
                      name={patient.Name}
                      firstName={patient.FirstName || null}
                      lastName={patient.LastName || null}
                      image={patient.ImageRef}
                      birthdate={Helpers.formatDate(patient.Birthday)}
                      lastUpdated={Helpers.timeStampToDate(patient.LastUpdate)}
                      newTests={
                        patient.PendingReview && patient.PendingReview.length > 0 ?
                        patient.PendingReview.length :
                        null
                      }
                      isLinked={patient.Source && patient.Source === 'Patient'}
                    />
                  </Link>
                )
              })}
            </div>
            <div className='floating-button-container'>
              <FloatingButton
                onClick={() => {
                  setIsSearchBarShowing(!isSearchBarShowing)
                  setIsSearchBarRendered(true)
                }}
                image={isSearchBarShowing ? XCircle : Search}
                text={isSearchBarShowing ? 'cancel' : 'search'}
              />
              {(createPatients && props.subData.status === 'active') && (
                <Link
                  to='add'
                  onClick={() => {
                    props.storePayload({ // Clear previous patient
                      patientCreated: false,
                      personImageUrl: null,
                      personImageName: null,
                      newPatient: {},
                    })
                  }}
                >
                  <FloatingButton
                    image={Add}
                    text='add'
                    onClick={() => {
                      props.storeValue('isMprocRequest', false)
                    }}
                  />
                </Link>
              )}
            </div>
          </div>}
          {tabs['remoteTestLink'] &&
          <TestLink />
          }
          {/* <Notification
            type={1}
            image={null}
            message={'Message here.'}
            time={'10:49'}
            isVisible={isNotificationVisible}
            onClick={onClickNotification}
          /> */}
        </main>
      </Fragment>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  firestore: state.firebaseReducer.firestore,
  signedIn: state.userReducer.signedIn,
  path: state.userReducer.path,
  patientList: state.userReducer.patientList,
  userData: state.firebaseReducer.userData,
  orgData: state.userReducer.orgData,
  orgPatientList: state.userReducer.orgPatientList,
  patientsData: state.userReducer.patientsData,
  isLoading: state.userReducer.isLoading,
  subData: state.userReducer.subscriptionData,
})

export default connect(
    mapStateToProps,
    {storeValue, storePayload, fetchPatientData},
)(Dashboard)

